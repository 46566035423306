import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { TechnicalObject } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export const DEFECTS_SOURCE = 'DEFECTS_SOURCE';
export const ACTIVITIES_SOURCE = 'ACTIVITIES_SOURCE';
export const OPERATIONS_SOURCE = 'OPERATIONS_SOURCE';

function useDefectSource(): HookedSource<TechnicalObject> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [DEFECTS_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.DEFECT_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.DEFECTS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'technicalObject.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useActivitiesSource(): HookedSource<TechnicalObject> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [ACTIVITIES_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.ACTIVITY_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ACTIVITIES}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'technicalObject.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useOperationsSource(): HookedSource<TechnicalObject> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [OPERATIONS_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.OPERATION_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.OPERATIONS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'technicalObject.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

export function useTechnicalObjectHookedSources(): HookedSource<TechnicalObject> {
  const defects = useDefectSource();
  const activities = useActivitiesSource();
  const operations = useOperationsSource();

  return { ...defects, ...activities, ...operations };
}
