import React from 'react';
import { useIntl } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import CachedIcon from '@material-ui/icons/Cached';
import {
  ScrollableSource,
  Table,
  TableHandle,
  TableToolbar,
  TableToolbarButton,
} from '@eas/common-web';
import { useColumns } from '@modules/work-order/work-order-columns';
import { useListenToWorkOrderChanges } from '@composite/websocket/listen-to-work-order-changes';
import { WorkOrder } from '@models';
import { useStyles } from '../planner-styles';
import { BulkUnplanSelectedWorkOrders } from './work-order-bulk-unplan';
import { WorkOrderRow } from './work-order-row';
import { useWorkOrderTableStyles } from './work-order-styles';

export function WorkOrderTable({
  workOrderTableRef,
  dataSource,
  dragging,
}: {
  workOrderTableRef: React.RefObject<TableHandle<WorkOrder>>;
  dataSource: ScrollableSource<WorkOrder>;
  dragging?: boolean;
}) {
  const columns = useColumns();
  const classes = useStyles();
  const useTableStyles = useWorkOrderTableStyles(dragging);
  const intl = useIntl();

  const { resendAllChangedWorkOrders, clearCache } =
    useListenToWorkOrderChanges({
      getRowId: (workOrder) => workOrder?.id ?? '',
      variant: 'table',
    });

  return (
    <div className={classes.bottomWindow}>
      <AutoSizer disableWidth={true} style={{ height: '100%' }}>
        {({ height }) => {
          return (
            <Table
              ref={workOrderTableRef}
              version={7}
              tableId="CALENDAR__WORK_ORDER_TABLE"
              tableName={intl.formatMessage({
                id: 'CALENDAR__WORK_ORDER_TABLE',
                defaultMessage: 'Pracovné príkazy',
              })}
              toolbarProps={{
                before: (
                  <>
                    <BulkUnplanSelectedWorkOrders clearCache={clearCache} />
                    <TableToolbarButton
                      label={<CachedIcon />}
                      onClick={() => {
                        clearCache?.();
                        workOrderTableRef?.current?.refresh();
                      }}
                      tooltip={intl.formatMessage({
                        id: 'EAS_TABLE_TOOLBAR_BTN_REFRESH',
                        defaultMessage: 'Obnoviť dáta',
                      })}
                      dataCy="EAS__TABLE__TOOLBAR__REFRESH"
                    />
                  </>
                ),
              }}
              ToolbarComponent={TableToolbar}
              columns={columns}
              source={dataSource}
              height={height}
              useStyles={useTableStyles}
              showNamedSettingsButton={true}
              showBulkActionButton={false}
              showSelectBox={true}
              showRefreshButton={false}
              showSearchbar={false}
              showReportButton={false}
              RowComponent={WorkOrderRow}
              showResetSortsButton={true}
              afterItemsRendered={resendAllChangedWorkOrders}
            />
          );
        }}
      </AutoSizer>
    </div>
  );
}
