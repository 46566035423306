import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DatedEvidence,
  FormAutocomplete,
  FormCheckbox,
  FormContext,
  FormFileTable,
  FormNumberField,
  FormPanel,
  FormTableField,
  TableField,
  TableFieldCells,
} from '@eas/common-web';
import { useWorkOrderStates } from '@modules/work-order-state/work-order-state-api';
import { useWorkOrderTypes } from '@modules/work-order-type/work-order-type-api';
import { Redirect } from '@components/redirect/redirect';
import { EvidenceBrowserUrl } from '@enums';
import { labelMapper, renameFile } from '@utils';
import { useColumns } from './history-state-columns';

export function GeneralFields() {
  const WorkOrderTypes = useWorkOrderTypes();
  const workOrderStates = useWorkOrderStates();

  const intl = useIntl();

  const { getFieldValues } = useContext(FormContext);

  const extServerFields = [
    'extServerLink1',
    'extServerLink2',
    'extServerLink3',
    'extServerLink4',
    'extServerLink5',
  ];

  const showExtAttachments = extServerFields.some(
    (field) => getFieldValues()[field]
  );

  const columns = useColumns();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__WORK_ORDERS__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormNumberField
          disabled
          name="workOrderId"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__CONTRACT_NUMBER"
              defaultMessage="Číslo zákazky"
            />
          }
        />
        <FormNumberField
          disabled
          name="operationNumber"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__OPERATION_NUMBER"
              defaultMessage="Číslo operácie"
            />
          }
        />
        <FormCheckbox
          name="received"
          label={
            <FormattedMessage
              id="ZSD__ABSENCE__DETAIL__FIELD_LABEL__RECEIVED"
              defaultMessage="PP prijatý na MK"
            />
          }
        />
        <FormNumberField
          disabled
          name="priority"
          label={
            <FormattedMessage
              id="ZSD__ABSENCE__DETAIL__FIELD_LABEL__PRIORITY"
              defaultMessage="Priorita"
            />
          }
        />
        <FormAutocomplete
          name="currentState"
          source={workOrderStates}
          label={
            <FormattedMessage
              id="ZSD__USERS__DETAIL__FIELD_LABEL__WORK_ORDER_STATE"
              defaultMessage="Stav"
            />
          }
          labelMapper={labelMapper}
          disabled={true}
        />
        <FormAutocomplete
          disabled
          name="workOrderType"
          source={WorkOrderTypes}
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__WORK_ORDER_TYPE"
              defaultMessage="Druh PP"
            />
          }
          labelMapper={labelMapper}
        />
        <DatedEvidence.DatedFields />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__STATES"
            defaultMessage="História stavov"
          />
        }
        expandable={true}
        defaultExpanded={false}
      >
        <FormTableField
          name={'historyStates'}
          disabled={true}
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          showDetailBtnCond={() => false}
          showToolbar={false}
          columns={columns}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__ATTACHMENTS"
            defaultMessage="Prílohy"
          />
        }
      >
        <FormFileTable
          name="attachments"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          changeFileName={(file, index) =>
            renameFile(file, index, getFieldValues().workOrderId)
          }
          allowedExtensions={[
            '.doc',
            '.docx',
            '.jpeg',
            '.jpg',
            '.ods',
            '.odt',
            '.pdf',
            '.png',
            '.rtf',
            '.webp',
            '.xls',
            '.xlsx',
          ]}
        />
      </FormPanel>
      {showExtAttachments && (
        <FormPanel
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__EXTERNAL_ATTACHMENTS"
              defaultMessage="Externé prílohy"
            />
          }
        >
          <TableField
            disabled={true}
            showToolbar={false}
            showDetailBtnCond={() => false}
            onChange={() => {}}
            columns={[
              {
                datakey: 'name',
                name: '',
                width: 80,
                CellComponent: function Cell(props) {
                  return (
                    <Redirect
                      url={props.rowValue?.link ?? EvidenceBrowserUrl.HOME}
                      id=""
                    />
                  );
                },
              },
              {
                datakey: 'link',
                name: intl.formatMessage({
                  id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__EXTERNAL_ATTACHMENT',
                  defaultMessage: 'Odkaz',
                }),
                width: 500,
                CellComponent: TableFieldCells.TextCell,
              },
            ]}
            value={extServerFields
              .map((field) => {
                if (getFieldValues()[field]) {
                  return {
                    name: field,
                    link: getFieldValues()[field] as string,
                  };
                }
              })
              .filter((item) => item)}
          />
        </FormPanel>
      )}
    </>
  );
}
