import _ from 'lodash';
import {
  ApiFilterOperation,
  Filter,
  ListSource,
  Params,
  abortableFetch,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { WorkOrder, WorkOrderAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useWorkOrders() {
  return useAutocompleteSource<WorkOrderAutocomplete>({
    url: `${EvidenceApiUrl.WORK_ORDERS}/autocomplete`,
  });
}

export function useWorkOrdersList(filters?: Filter[]) {
  return useFetch<ListSource<WorkOrderAutocomplete>>(
    `${EvidenceApiUrl.WORK_ORDERS}/list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        size: -1,
        sort: [
          {
            field: 'workOrderId',
            order: 'ASC',
            type: 'FIELD',
            missing: 'LAST',
          },
        ],
        filters: [
          {
            operation: ApiFilterOperation.AND,
            filters: [
              {
                field: 'currentState',
                operation: ApiFilterOperation.NOT_NULL,
              },
              ...(filters ?? []),
            ],
          },
        ],
      }),
    }
  );
}

export function useWorkOrdersAbortableList(api: string, params: Params) {
  return abortableFetch(api, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...params,
      filters: [
        {
          operation: ApiFilterOperation.AND,
          filters: [
            {
              field: 'currentState',
              operation: ApiFilterOperation.NOT_NULL,
            },
            ...(params.filters ?? []),
          ],
        },
      ],
    }),
  });
}

export function updateState(id: string, code: string) {
  const query = new URLSearchParams({
    code: code.toString(),
  });

  return abortableFetch(
    `${EvidenceApiUrl.WORK_ORDERS}/${id}/change/state?${query.toString()}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    }
  );
}

export function updateWorkOrder(id: string, body: Partial<WorkOrder>) {
  return abortableFetch(`${EvidenceApiUrl.WORK_ORDERS}/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}

export function updateTravelTime(
  id: string,
  body: { travelPrevious: number; travelNext: number }
) {
  const query = new URLSearchParams({
    travelNext: body.travelNext.toString(),
    travelPrevious: body.travelPrevious.toString(),
  });

  return abortableFetch(
    `${EvidenceApiUrl.WORK_ORDERS}/${id}/update/travel?${query.toString()}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    }
  );
}

export function planWorkOrder(
  id: string,
  body: {
    ownerId: string;
    ownerType: 'TECHNICIAN' | 'MOBILE_UNIT';
    plannedFrom: string;
    plannedTo: string;
    travelPrevious?: number;
    travelNext?: number;
  },
  isMultiday = false
) {
  return abortableFetch(
    `${EvidenceApiUrl.WORK_ORDERS}/${id}/plan${isMultiday ? '/multi-day' : ''}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(isMultiday ? _.omit(body, 'plannedTo') : body),
    }
  );
}

export function completePlan(body: {
  calendarOwners: {
    id: string;
    type: 'TECHNICIAN' | 'MOBILE_UNIT';
  }[];
  from: string;
  to: string;
}) {
  return abortableFetch(`${EvidenceApiUrl.WORK_ORDERS}/plan/complete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}

export function validateWorkOrder(
  id: string,
  body: {
    ownerId: string;
    ownerType: 'TECHNICIAN' | 'MOBILE_UNIT';
    plannedFrom: string;
    plannedTo: string;
  },
  isMultiDay?: boolean
) {
  return abortableFetch(
    `${EvidenceApiUrl.WORK_ORDERS}/${id}/plan/${
      isMultiDay ? 'multi-day/' : ''
    }validate`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(isMultiDay ? _.omit(body, 'plannedTo') : body),
    }
  );
}

export function unplanWorkOrder(id: string) {
  return abortableFetch(`${EvidenceApiUrl.WORK_ORDERS}/${id}/unplan`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  });
}
