import { WorkOrder } from '@models';

export function renameFile(file: File, index: number, workOrder: WorkOrder) {
  const type =
    window.location.href.includes('test') ||
    window.location.href.includes('dev') ||
    window.location.href.includes('local')
      ? 'T'
      : 'P';

  const systemName = workOrder.systemName?.replace(' ', '');
  const dateTime = new Date();

  // yyyyMMDD
  const date =
    dateTime.getFullYear().toString() +
    (dateTime.getMonth() + 1).toString().padStart(2, '0') +
    dateTime.getDate().toString().padStart(2, '0');
  // HHmmss
  const time =
    dateTime.getHours().toString().padStart(2, '0') +
    dateTime.getMinutes().toString().padStart(2, '0') +
    dateTime.getSeconds().toString().padStart(2, '0');

  const name = `${systemName}_${type}-${workOrder.workOrderId}_${workOrder.operationNumber}-${index}-${date}_${time}.jpg`;
  return new File([file], name, { type: file.type });
}
