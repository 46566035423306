import { DragEndEvent } from '@dnd-kit/core';
import dayjs from 'dayjs';
import { useContext } from 'react';
import {
  ScrollableSource,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import {
  CalendarDataSourceType,
  CalendarHandle,
} from '@modules/planner/calendar/calendar-types';
import {
  addStartingPointCoords,
  stringifyCoords,
} from '@modules/planner/unit-menu/utils';
import {
  planWorkOrder,
  updateTravelTime,
} from '@modules/work-order/work-order-api';
import { CalendarShift } from '@composite/calendar/types';
import { getIntervals } from '@composite/calendar/utils/get-template';
import { BRATISLAVA_COORDS_GPS_POINT } from '@composite/map';
import { getDrivingInfo } from '@composite/map/utils/get-driving-info';
import { GPSPoint, WorkOrder } from '@models';
import { WorkOrderDto, prepareDto } from '../utils/prepare-dto';
import { useValidateWorkOrder } from './validate';

const getIsMultiday = ({
  workOrder,
  calendarStartDate,
  shifts,
}: {
  workOrder: WorkOrderDto;
  calendarStartDate: string;
  shifts: (CalendarShift | undefined)[];
}): boolean => {
  const MULTIDAY_NUM_HOURS_TOLERANCE = 1;

  // dayjs date where should new WO end
  const plannedTo = dayjs.tz(workOrder.plannedTo);
  const plannedFrom = dayjs.tz(workOrder.plannedFrom);

  // if planned to is different day then plannedTo, it is definitely multiday
  if (!plannedFrom.isSame(plannedTo, 'day')) {
    return true;
  }

  // index of the shift where the new WO should end based on duration only
  const targetShiftIndex = dayjs(plannedTo.startOf('day')).diff(
    calendarStartDate,
    'day'
  );

  // the last valid shift interval (ending hour) of the day where the new WO should end based on duration only
  const lastValidShiftInterval = getLastWorkIntervalOfShift(
    shifts?.[targetShiftIndex]
  )?.endingHour;

  // datetime (dayjs) with tolerance added - if the new WO ends after this time it is MULTIDAY WO
  const maxValidHourOfTheDay = dayjs(
    `${plannedTo.startOf('day').format('YYYY-MM-DD')}T${lastValidShiftInterval}`
  ).add(MULTIDAY_NUM_HOURS_TOLERANCE, 'hour');

  return maxValidHourOfTheDay.isBefore(plannedTo);
};

const planSingleWorkOrder = async ({
  newWorkOrder,
  newWorkOrderDto,
  prevWorkOrder,
  nextWorkOrder,
  unitStartingPoint,
  isMultiDay,
}: {
  nextWorkOrder: WorkOrder | undefined;
  prevWorkOrder: WorkOrder | undefined;
  newWorkOrder: WorkOrder;
  newWorkOrderDto: WorkOrderDto;
  unitStartingPoint: GPSPoint;
  isMultiDay: boolean;
}) => {
  const newWorkOrderTravel = {
    previous: 0,
    next: 0,
  };
  if (!nextWorkOrder) {
    const woCoords = stringifyCoords([newWorkOrder]);
    const travelNextCoords = addStartingPointCoords(
      woCoords,
      unitStartingPoint,
      'end'
    );
    const travelTime = await getDrivingInfo(travelNextCoords);
    newWorkOrderTravel.next = Math.round(travelTime.duration);
  }
  if (!prevWorkOrder) {
    const woCoords = stringifyCoords([newWorkOrder]);
    const travelPreviousCoords = addStartingPointCoords(
      woCoords,
      unitStartingPoint,
      'start'
    );
    const travelTime = await getDrivingInfo(travelPreviousCoords);
    newWorkOrderTravel.previous = Math.round(travelTime.duration);
  } else {
    const coordsPrevious = stringifyCoords([prevWorkOrder, newWorkOrder]);
    const travelTime = await getDrivingInfo(coordsPrevious);
    newWorkOrderTravel.previous = Math.round(travelTime.duration);
  }

  await planWorkOrder(
    newWorkOrderDto.id,
    {
      ownerType: newWorkOrderDto.unitType,
      ownerId: newWorkOrderDto.unitId,
      plannedFrom: newWorkOrderDto.plannedFrom,
      plannedTo: newWorkOrderDto.plannedTo,
      travelNext: newWorkOrderTravel.next,
      travelPrevious: newWorkOrderTravel.previous,
    },
    isMultiDay
  ).json();

  if (prevWorkOrder && Number(prevWorkOrder?.travelNext) > 0) {
    await updateTravelTime(prevWorkOrder.id, {
      travelNext: 0,
      travelPrevious: prevWorkOrder.travelPrevious ?? 0,
    }).json();
  }
};

export function useDragEnd({
  calendarSource,
  workOrderSource,
  edgeWorkOrders,
  setEdgeWorkOrders,
  setMovingWorkOrders,
  calendarRef,
}: {
  calendarSource: CalendarDataSourceType;
  workOrderSource: ScrollableSource<WorkOrder>;
  edgeWorkOrders: [WorkOrder | undefined, WorkOrder | undefined];
  setEdgeWorkOrders: (
    orders: [WorkOrder | undefined, WorkOrder | undefined]
  ) => void;
  setMovingWorkOrders: (orders: WorkOrder[]) => void;
  calendarRef: React.RefObject<CalendarHandle>;
}) {
  const { showSnackbar } = useContext(SnackbarContext);
  const { validate } = useValidateWorkOrder();

  return async (event: DragEndEvent) => {
    if (event.collisions?.length !== 1) {
      setMovingWorkOrders([]);

      return;
    }

    let [prevWorkOrder] = edgeWorkOrders;
    const [, nextWorkOrder] = edgeWorkOrders;

    const workOrders = event.active.data.current as WorkOrder[];
    const unitStartingPoint: GPSPoint =
      event.collisions[0]?.data?.droppableContainer?.data?.current
        ?.startingPoint ?? BRATISLAVA_COORDS_GPS_POINT;

    const unitId =
      event?.collisions?.[0]?.data?.droppableContainer?.data?.current?.unitId;

    const unitIndex = (calendarSource?.yAxis?.data ?? []).findIndex(
      (row) => row.id === unitId
    );

    //////////////////////
    //////////////////////
    //////////////////////

    if (workOrders.length === 1) {
      const calendarStartDate = dayjs.unix(
        calendarRef.current?.currentDate ?? 0
      );

      const newWorkOrder = workOrders[0];
      const newWorkOrderDto = prepareDto({
        currentDate: calendarStartDate.unix(),
        collision: event.collisions[0],
        workOrder: workOrders[0],
        offset: 0,
      });

      if (!newWorkOrderDto) return;

      const isMultiDay = getIsMultiday({
        workOrder: newWorkOrderDto,
        calendarStartDate: calendarStartDate
          .startOf('day')
          .format('YYYY-MM-DD'),
        shifts: calendarSource?.yAxis?.data?.[unitIndex].shifts ?? [],
      });

      const result = await validate(newWorkOrderDto, isMultiDay);
      if (result.ok === false) return;

      //////////////////////////////////

      await planSingleWorkOrder({
        prevWorkOrder,
        nextWorkOrder,
        newWorkOrder,
        newWorkOrderDto,
        unitStartingPoint,
        isMultiDay,
      });

      /////////////////////////////////////////
    } else {
      let travelTimeAcc = 0;
      const calendarStartDate = dayjs.unix(
        calendarRef.current?.currentDate ?? 0
      );
      for (let i = 0; i < workOrders.length; i++) {
        const newWorkOrderTravel = {
          previous: 0,
          next: 0,
        };
        try {
          //let coordsString = '';
          if (!prevWorkOrder) {
            let coordsString = stringifyCoords([workOrders[i]]);
            coordsString = addStartingPointCoords(
              coordsString,
              unitStartingPoint,
              'start'
            );
            const travelTime = await getDrivingInfo(coordsString);
            newWorkOrderTravel.previous = travelTime.duration;
          } else {
            const coordsString = stringifyCoords([
              prevWorkOrder,
              workOrders[i],
            ]);
            const travelTime = await getDrivingInfo(coordsString);
            newWorkOrderTravel.previous = travelTime.duration;
          }

          // skip for 1st work order
          if (i !== 0) {
            travelTimeAcc += Math.round(newWorkOrderTravel.previous);
          }

          const newWorkOrder = prepareDto({
            currentDate: calendarRef.current?.currentDate ?? 0,
            collision: event.collisions[0],
            workOrder: workOrders[i],
            offset: travelTimeAcc,
          });

          if (!newWorkOrder) return;

          const isMultiDay = getIsMultiday({
            workOrder: newWorkOrder,
            calendarStartDate: calendarStartDate
              .startOf('day')
              .format('YYYY-MM-DD'),
            shifts: calendarSource?.yAxis?.data?.[unitIndex].shifts ?? [],
          });

          const result = await validate(newWorkOrder);

          if (result.ok === false) return;

          // Naplanuj aktualny PP

          const plannedWorOrderResponse = (await planWorkOrder(
            newWorkOrder.id,
            {
              ownerType: newWorkOrder.unitType,
              ownerId: newWorkOrder.unitId,
              plannedFrom: newWorkOrder.plannedFrom,
              plannedTo: newWorkOrder.plannedTo,
            },
            isMultiDay
          ).json()) as WorkOrder;

          const { startTime, endTime } = plannedWorOrderResponse;

          // we cant use work order's duration, because endTime - startTime != duration in multiday work orders
          travelTimeAcc += dayjs(endTime).diff(dayjs(startTime), 'second');

          ////////////////////////////////////////
          /////// UPDATE TRAVEL TIMES ////////////
          ////////////////////////////////////////

          if (i === workOrders.length - 1) {
            if (
              !nextWorkOrder ||
              dayjs(newWorkOrder.plannedTo).isAfter(
                dayjs(nextWorkOrder.endTime)
              )
            ) {
              // Som na poslednom PP z vybranych DnD, zaroven exituje nejake next PP
              // a zaroven exitujuci PP konci driv nez tie co som dropol
              // musim novemu poslednemu nastavit travelNext a tomu exitujucemu travelNext = 0

              let coordsTravelNext = stringifyCoords([newWorkOrder]);
              coordsTravelNext = addStartingPointCoords(
                coordsTravelNext,
                unitStartingPoint,
                'end'
              );

              const travelTimeNext = await getDrivingInfo(coordsTravelNext);
              await updateTravelTime(newWorkOrder.id, {
                travelNext: Math.round(travelTimeNext.duration),
                travelPrevious: Math.round(newWorkOrderTravel.previous),
              }).json();

              if (nextWorkOrder) {
                // updatni povodny posledny PP ak exituje
                await updateTravelTime(nextWorkOrder.id, {
                  travelNext: 0,
                  travelPrevious: nextWorkOrder.travelPrevious ?? 0,
                }).json();
              }
            } else {
              // Som na poslednom PP z vybranych DnD, zaroven exituje nejake next PP,
              // ale zaroven exitujuci next PP nekonci driv nez tie co som dropol
              await updateTravelTime(newWorkOrder.id, {
                travelNext: 0,
                travelPrevious: Math.round(newWorkOrderTravel.previous),
              }).json();

              // Pripad ze som na poslednom PP a zaroven ten konci pred tym nez povodny PP
              const coords = stringifyCoords([newWorkOrder, nextWorkOrder]);
              const travelTimeLastPrevious = await getDrivingInfo(coords);
              // updatni povodny posledny PP
              await updateTravelTime(nextWorkOrder.id, {
                travelNext: nextWorkOrder.travelNext ?? 0,
                travelPrevious: Math.round(travelTimeLastPrevious.duration),
              }).json();
            }
          } else {
            // nie som na poslednom PP z vybranych DnD
            await updateTravelTime(newWorkOrder.id, {
              travelNext: 0,
              travelPrevious: Math.round(newWorkOrderTravel.previous),
            }).json();
          }
          prevWorkOrder = workOrders[i];
        } catch (error) {
          console.error(error);

          showSnackbar(
            'Pracový príkaz sa nepodarilo naplánovať. \n\n' +
              JSON.stringify(error, null, 2),
            SnackbarVariant.ERROR
          );
        }
      }
    }

    setMovingWorkOrders([]);
    setEdgeWorkOrders([undefined, undefined]);

    workOrderSource.reset();
    workOrderSource.loadMore();
    calendarSource.yAxis.refreshData();
  };
}

const getLastWorkIntervalOfShift = (shift?: CalendarShift) => {
  if (!shift) {
    return undefined;
  }
  const todayIntervals = getIntervals(shift);
  const workShifts = todayIntervals.filter(
    (interval) => interval.type !== 'V' && interval.type !== 'O'
  );
  const lastShift =
    workShifts.length > 0 ? workShifts[workShifts.length - 1] : undefined;

  return lastShift;
};
