import React, { ComponentPropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScrollableSource } from '@eas/common-web';
import { CollectionField } from '@components/collection-field/collection-field';
import { DictionaryAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './defect-columns';

interface DefectFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField<DictionaryAutocomplete>>
  > {
  source: ScrollableSource<DictionaryAutocomplete>;
}

export function DefectField({ source, ...overrides }: DefectFieldProps) {
  return (
    <CollectionField<DictionaryAutocomplete>
      title={
        <FormattedMessage
          id="ZSD__FIELD_LABEL__DEFECTS"
          defaultMessage="Nedostatky"
        />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        hide: true,
        getUrl: () => EvidenceApiUrl.DEFECTS,
      }}
      readAction={{
        getUrl: (collectionId) => `${EvidenceApiUrl.DEFECTS}/${collectionId}`,
      }}
      updateAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.DEFECTS}/${collectionId}`,
      }}
      deleteAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.DEFECTS}/${collectionId}`,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.DEFECTS}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.DEFECTS}/${collectionId}`,
      }}
      {...overrides}
    />
  );
}
