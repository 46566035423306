import React from 'react';
import {
  DatedEvidence,
  DictionaryEvidence,
  FormAutocomplete,
  FormNumberField,
} from '@eas/common-web';
import { useDepartments } from '@modules/department/department-api';
import { useWorkOrderTypes } from '@modules/work-order-type/work-order-type-api';

export function Fields() {
  const workOrderTypes = useWorkOrderTypes();
  const departments = useDepartments();
  return (
    <>
      <DictionaryEvidence.FieldName />
      <FormAutocomplete
        name="workOrderTypes"
        label="Druhy pracovných príkazov"
        source={workOrderTypes}
        multiple
      />
      <FormAutocomplete
        name="departments"
        label="Pracoviská"
        source={departments}
        multiple
      />
      <FormNumberField name="operationNumber" label="Poradové číslo" />
      <DatedEvidence.DatedFields />
    </>
  );
}
