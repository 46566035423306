import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { EvidenceContext, FormPanel, FormTextField } from '@eas/common-web';
import { ActivityField } from '@composite/activity-collection/activity-field';
import { DefectField } from '@composite/defect-collection/defect-field';
import { OperationField } from '@composite/operation-collection/operation-field';
import {
  ACTIVITIES_SOURCE,
  DEFECTS_SOURCE,
  OPERATIONS_SOURCE,
} from './technical-object-hooked-sources';

export function Fields() {
  const { hookedSources } = useContext(EvidenceContext);

  const activitySource = hookedSources[ACTIVITIES_SOURCE]?.source;
  const defectSource = hookedSources[DEFECTS_SOURCE]?.source;
  const operationSource = hookedSources[OPERATIONS_SOURCE]?.source;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__TECHNICAL_OBJECT__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormTextField
          name="fid"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="FID"
            />
          }
        />
        <FormTextField
          name="type.name"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__TYPE"
              defaultMessage="Typ"
            />
          }
        />
        <ActivityField source={activitySource} />
        <OperationField source={operationSource} />
        <DefectField source={defectSource} />
      </FormPanel>
    </>
  );
}
